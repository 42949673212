import { Flex, FlexProps, useTheme } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

import { FoamSpinner } from '~/components/FoamSpinner';

interface Props extends FlexProps {
  isVisible?: boolean;
}

export const AppLoader: React.FC<Props> = ({ isVisible = true, ...props }) => {
  const { zIndices } = useTheme();

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Flex
            aria-label="Loading app..."
            data-testid="app-loader"
            position="absolute"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            zIndex={zIndices.foamLoader}
            backgroundColor="neutral.100"
            {...props}
          >
            <FoamSpinner />
          </Flex>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
