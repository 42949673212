import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { withSessionRedirect } from '~/components/withSessionRedirect';
import { routes } from '~/constants/routes';

function Index() {
  const router = useRouter();

  useEffect(() => {
    router.push(routes.foam.login);
  }, [router]);

  return null;
  // <AppLoader />;
}

export default withSessionRedirect(Index);
