/* eslint-disable react/display-name */

import { Auth, NewTokenResponse, RefreshTokenParams } from '@orca/api-auth';
import router from 'next/router';
import { FC, useEffect, useState } from 'react';

import { AppLoader } from '~/components/AppLoader';
import { accessTokenKey, refreshTokenKey } from '~/constants/local_storage';
import { routes } from '~/constants/routes';
import { useAuth } from '~/contexts/auth';
import { AuthTokens } from '~/types/AuthTokens';

/* 
 If you had a stored accessToken and refreshToken, check if it's still valid and redirect to the app else remove the tokens and finally redirect to login.
 If you did not have a stored accessToken and refreshToken, show the component
*/
export const withSessionRedirect = (Component: FC<any>) => (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);

  const auth = useAuth();

  useEffect(() => {
    const checkTokens = async () => {
      let authTokens: AuthTokens = {
        accessToken: window.localStorage.getItem(accessTokenKey) as string,
        refreshToken: window.localStorage.getItem(refreshTokenKey) as string,
      };

      if (authTokens.accessToken && authTokens.refreshToken) {
        try {
          const resp: NewTokenResponse = (await Auth.refreshAccessToken({
            refreshToken: authTokens.refreshToken,
          } as RefreshTokenParams)) as NewTokenResponse;

          auth.redirectToApp({
            accessToken: resp.accessToken,
            refreshToken: resp.refreshToken,
          });
        } catch (error) {
          router.push(routes[auth.platform].logout);
        }
      } else {
        setLoading(false);
      }
    };

    checkTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? <AppLoader /> : <Component {...props} />;
};
